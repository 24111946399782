<template>
    <div id="toastmaster" class="toast-container d-flex flex-column align-items-end">
        <div
            :key="indx + 'toast'"
            v-for="(items, indx) in toasts"
            class="toast show"
            :class="items.bodyStyle || 'text-white bg-secondary bg-gradient'"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
        >
            <div class="toast-header text-white a-blink" :class="items.headerStyle || 'bg-dark'">
                <strong class="me-auto">{{items.header}}</strong>
                <button type="button" class="btn-close btn-close-white" @click="hide(indx)"></button>
            </div>
            <div class="toast-body" v-if="items.body || items.buttons">
                <div v-html="items.body"></div>
                <div v-if="items.buttons"
                    class="marg-small"
                >
                    <button
                        :key="bIndex+'button'+indx+'toasts'"
                        v-for="(buttons, bIndex) in items.buttons"
                        type="button"
                        class="btn btn-sm text-white"
                        :class="buttons.color || 'btn-primary'"
                        @click="buttons.action"
                    >{{buttons.text}}</button>
                </div>
                <div
                    class="default-buttons align-right marg-small"
                    v-if="indx === toasts.length - 1"
                >
                    <button
                        type="button"
                        class="btn btn-sm text-white"
                        :class="'btn-info'"
                        @click="$emit('disable-help')"
                    >Disable help on this page</button>
                    <button
                        type="button"
                        class="btn btn-sm text-white"
                        :class="'btn-info'"
                        @click="$emit('close-all')"
                    >Close all toasts</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Toast from 'bootstrap/js/dist/toast'

export default {
    name: 'Toasts',
    data() {
        return {
        }
    },
    props: ["toasts"],
    created() {
        const toastElList = [].slice.call(document.querySelectorAll('.toast'))
        const toastList = toastElList.map((toastEl) => new Toast(toastEl, {}))
    },
    methods: {
        hide(indx) {
            //  this.$emit('hide-toast')
            console.log('this toasts', this.toasts, 'indx', indx)
            this.toasts.splice(indx, 1)
        }
    }
}
</script>

<style lang="sass">
    .container-fluid
        #toastmaster.toast-container
            /* the width of the padding on container-fluid */
            width: calc(100% - 36px)
        .container-fluid #toastmaster.toast-container
            /* the width of the padding on container-fluid */
            width: calc(100% - 18px - 18px)
    body.dark
        #toastmster.toast-container
            .bg-light, .bg-light a
                color: #343a40 !important
    #toastmaster.toast-container
        position: fixed
        z-index: 999
        bottom: .5em
        .text-dark, .text-dark *, .text-dark i
            color: #343a40 !important
        .text-white, .text-white *, .text-white i
            color: #fff !important
        .toast
            font-size: 1rem
        .default-buttons
            > :not(:last-child)
                margin-right: 10px
    @media only screen and (min-width: 768px)
        #toastmaster.toast-container
            width: 100%
            right: 1em
            bottom: 1em
            .toast
                /* default */
                font-size: .875rem
</style>
