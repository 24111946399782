import { SS } from '@/assets/constants'
import { UseAPI, promiseDelay } from '@/assets/common'

// settings
const DEBUGGING = true

// calls
const API_USER_SETTINGS = '/get/user-settings'

// promises
const WAIT = 'wait'
const FAIL = 'fail'
const CONVERSION_FAIL = 'Failed to convert db information to options'
const GET_USER_SETTINGS_FAIL = 'Failed to retrieve information from db'

function getFromStorage() {
    const valueFromStorage = SS.get(SS.USER_SETTINGS_COOKIE)
    if (valueFromStorage) return valueFromStorage
    return false
}

function saveToStorage(value) {
    SS.set(SS.USER_SETTINGS_COOKIE, value)
}

function rowsToObj(arr) {
    const rowsAsObj = {}
    arr.forEach((i) => {
        if ('context' in i) rowsAsObj[i.context] = i.val
    })
    return rowsAsObj
}

async function getUserSettings() {
    const fromStorage = getFromStorage()
    if (fromStorage) return Promise.resolve(fromStorage)
    console.log('calling user-settings')
    return UseAPI(API_USER_SETTINGS, { method: "POST" })
    .catch((err) => {
        if (DEBUGGING) console.log('Db error', err)
        return Promise.reject(GET_USER_SETTINGS_FAIL)
    })
    .then((result) => {
        const converted = rowsToObj(result.items)
        SS.set(SS.USER_SETTINGS_COOKIE, converted)
        if (DEBUGGING) console.log('result of getUserSettings', converted)
        return converted
    })
    .catch((err) => {
        console.log('Failed to getUserSettings', err)
        return Promise.reject(CONVERSION_FAIL)
    })
}

function precheck() {
    if (SS.get(SS.AWAITING_FIRST_HEARTBEAT)) {
        return Promise.reject(WAIT)
    }
    if (SS.get(SS.PUBLIC_API) && SS.get(SS.AWAITING_FIRST_HEARTBEAT) === false) {
        // use the public api
        return Promise.reject(FAIL)
    }
    return Promise.resolve
}

/**
 * @param {string} key - the key on the database
 */
export default function getSavedOptions(key) {
    return Promise.resolve()
    .then(precheck)
    .catch((err) => {
        switch (err) {
            case WAIT:
                // rerun
                return promiseDelay(2000)
                .then(() => {
                    console.log('attempting to set late')
                    return precheck()
                })
                .then(() => {
                    console.log('precheck passed second time')
                })
                .catch(() => {
                    console.log('Assuming were not logged in')
                })
            case FAIL:
            default:
                return Promise.resolve() // its, fine end up passing a {} and then theres no keys to copy over
        }
    })
    .then(getUserSettings) // resolves an object
    // eslint-disable-next-line arrow-body-style
    .then((options) => {
        saveToStorage(options)
        return options
    })
    .then((options) => {
        if (key && key in options) return options[key]
        return {}
    })
}
